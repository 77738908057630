body {
  background-color: #f0f2f5;

  .header {
    position: relative;
    background-color: #f0f2f5;
    text-align: center;
    .title {
      color: #1890ff;
      margin: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .content {
    .content-mid {
      min-height: 75vh;
      .column-mid {
        text-align: center;
        .img {
          width: 100%;
          max-width: 100%;
        }
        .imgLoading {
          filter: blur(5px);
        }
      }
    }
    .content-bot {
      padding-top: 24px;
      position: relative;
      .button {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  .footer {
    text-align: center;
    font-size: smaller;
    font-style: italic;
  }
}

@media screen and(min-width: 1024px) {
  body {
    .content {
      .content-mid {
        .column-mid {
          .img {
            max-width: 45vw;
            max-height: 70vh;
            width: auto;
            height: auto;
          }
        }
      }
    }
  }
}
